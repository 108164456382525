module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#4B99C1","theme_color":"#4B99C1","display":"minimal-ui","icon":"src/images/ic-topico.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"abab7b6505c080c461068be407fa0ac0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/home/runner/work/denario-web-front/denario-web-front/src/languages","languages":["en","pt"],"defaultLanguage":"pt","redirect":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-firebase/gatsby-browser.js'),
      options: {"plugins":[],"credentials":{"apiKey":"AIzaSyC5_HU6cfkBsDor5BHoPtAc1rutnoqRuPg","authDomain":"denario-387117.firebaseapp.com","databaseURL":"https://denario-387117.firebaseio.com/","projectId":"denario-387117","storageBucket":"denario-387117.appspot.com","messagingSenderId":"25731643725","appId":"1:25731643725:web:b0bb5372b7f73f38471f04","measurementId":"G-F9KPCV94P1"},"features":{"auth":true,"database":true,"firestore":false,"storage":false,"messaging":false,"functions":true,"performance":false,"analytics":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
