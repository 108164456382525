// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-campanha-natal-nestle-compartilhar-alegria-sortear-carta-js": () => import("./../../../src/pages/campanha-natal-nestle/compartilhar-alegria/sortear-carta.js" /* webpackChunkName: "component---src-pages-campanha-natal-nestle-compartilhar-alegria-sortear-carta-js" */),
  "component---src-pages-campanha-natal-nestle-confirmacao-js": () => import("./../../../src/pages/campanha-natal-nestle/confirmacao.js" /* webpackChunkName: "component---src-pages-campanha-natal-nestle-confirmacao-js" */),
  "component---src-pages-campanha-natal-nestle-js": () => import("./../../../src/pages/campanha-natal-nestle.js" /* webpackChunkName: "component---src-pages-campanha-natal-nestle-js" */),
  "component---src-pages-campanhas-js": () => import("./../../../src/pages/campanhas.js" /* webpackChunkName: "component---src-pages-campanhas-js" */),
  "component---src-pages-doar-confirmacao-js": () => import("./../../../src/pages/doar/confirmacao.js" /* webpackChunkName: "component---src-pages-doar-confirmacao-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instituicoes-cadastrar-js": () => import("./../../../src/pages/instituicoes/cadastrar.js" /* webpackChunkName: "component---src-pages-instituicoes-cadastrar-js" */),
  "component---src-pages-instituicoes-js": () => import("./../../../src/pages/instituicoes.js" /* webpackChunkName: "component---src-pages-instituicoes-js" */),
  "component---src-pages-live-js": () => import("./../../../src/pages/live.js" /* webpackChunkName: "component---src-pages-live-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-perfil-doacoes-js": () => import("./../../../src/pages/perfil/doacoes.js" /* webpackChunkName: "component---src-pages-perfil-doacoes-js" */),
  "component---src-pages-perfil-js": () => import("./../../../src/pages/perfil.js" /* webpackChunkName: "component---src-pages-perfil-js" */),
  "component---src-pages-privacidade-js": () => import("./../../../src/pages/privacidade.js" /* webpackChunkName: "component---src-pages-privacidade-js" */),
  "component---src-pages-termos-js": () => import("./../../../src/pages/termos.js" /* webpackChunkName: "component---src-pages-termos-js" */),
  "component---src-templates-campaign-index-js": () => import("./../../../src/templates/campaign/index.js" /* webpackChunkName: "component---src-templates-campaign-index-js" */),
  "component---src-templates-institution-index-js": () => import("./../../../src/templates/institution/index.js" /* webpackChunkName: "component---src-templates-institution-index-js" */),
  "component---src-templates-live-index-js": () => import("./../../../src/templates/live/index.js" /* webpackChunkName: "component---src-templates-live-index-js" */)
}

