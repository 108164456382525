const ChristmasCampaign = {
  hidePageFooter: () => {
    const $footer = document.querySelector('.main-footer')
    if ($footer) {
      $footer.style.display = 'none'
    }
  },
  hidePageHeader: () => {
    const $header = document.querySelector('.main-header')
    if ($header) {
      $header.style.visibility = 'hidden'
    }
  },
}

const RouteUpdate = {
  scrollWithPreSelectedValue: location => {
    const hasPredeterminedAmount = location.search.includes('value=')

    if (hasPredeterminedAmount) {
      setTimeout(() => {
        const donationSection = document.querySelector('#donation-section')

        if (donationSection) {
          window.scrollTo({
            top: donationSection.offsetTop - 20,
            behavior: 'smooth',
          })
        }
      }, 0)
    }
  },
  modifyPageWhenIsCheckout: location => {
    const isCheckout = location.search.includes('checkout')

    sessionStorage.setItem(
      '@denario_app/settings',
      JSON.stringify({ isCheckout })
    )

    setTimeout(() => {
      if (isCheckout) {
        const elementsToHide = document.querySelectorAll('.hide-on-checkout')

        elementsToHide.forEach(element => {
          element.style.display = 'none'
        })
      }
    }, 0)
  },
  modifyPageWhenIsChristmasCampaign: location => {
    const sessionData = JSON.parse(
      sessionStorage.getItem('@denario_app/christmas_campaign')
    )

    setTimeout(() => {
      if (sessionData && sessionData.christmas_campaign) {
        ChristmasCampaign.hidePageFooter()
        ChristmasCampaign.hidePageHeader()

        const elementsToHide = document.querySelectorAll(
          '.hide-on-christmas-campaign'
        )

        elementsToHide.forEach(element => {
          element.style.display = 'none'
        })
      }
    }, 0)
  },
  checkURLSelectedLanguage: location => {
    const urlHasDuplicatedLanguages =
      location.pathname.includes('/en/pt') ||
      location.pathname.includes('/pt/en')

    if (urlHasDuplicatedLanguages) {
      const newPathWithFixedLanguage = location.pathname.substr(3)
      const newLocation = `${location.origin}${newPathWithFixedLanguage}`

      window.location.href = newLocation
    }
  },
}

exports.onRouteUpdate = ({ location }) => {
  RouteUpdate.modifyPageWhenIsCheckout(location)
  RouteUpdate.modifyPageWhenIsChristmasCampaign(location)
  RouteUpdate.scrollWithPreSelectedValue(location)
  RouteUpdate.checkURLSelectedLanguage(location)
}
